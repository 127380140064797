@import '../mixins/fonts.scss';
@import '../mixins/colors.scss';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; // for some Androids
}

html,
button,
input,
textarea,
select {
  outline: none;
  border: none;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;

  &:focus {
    outline: none;
  } //removes FF dotted outline
}

html {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html {
  font-size: 20px;
}

html,
body {
  height: 100%;
}
body {
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.3em;
  background-color: $light;
  color: $brown;
  @include baseFont;
  font-size: 1.6rem;
}

p,
a,
li {
  line-height: 1.3em;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: 1.1em;
}

@media screen and (max-width: 1200px),
  (orientation: landscape) and (max-height: 1200px) {
  html {
    font-size: 19px;
  }
}

@media screen and (max-width: 991px),
  (orientation: landscape) and (max-height: 991px) {
  html {
    font-size: 18px;
  }
}

@media screen and (max-width: 800px),
  (orientation: landscape) and (max-height: 800px) {
  html {
    font-size: 17px;
  }
}

@media screen and (max-width: 680px),
  (orientation: landscape) and (max-height: 680px) {
  html {
    font-size: 16px;
  }
}

@media screen and (max-width: 465px),
  (orientation: landscape) and (max-height: 465px) {
  html {
    font-size: 15px;
  }
}

@media screen and (max-width: 340px),
  (orientation: landscape) and (max-height: 340px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 260px),
  (orientation: landscape) and (max-height: 260px) {
  html {
    font-size: 13px;
  }
}
