@mixin baseFont {
  @include sans;
}

// system fonts
$systemSans: system-ui, -apple-system, BlinkMacSystemFont, 'Open Sans',
  'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
  sans-serif;

// $systemSerif: 'Apple Garamond', 'Baskerville', 'Droid Serif', Garamond, Georgia,
//   Cambria, 'Times New Roman', Times, serif;

$systemMonospace: monospace;

@mixin mono {
  font-family: $systemMonospace;
  letter-spacing: -0.04em;
  font-size: 77%;
}
@mixin park {
  font-family: 'NationalPark', $systemSans;
  letter-spacing: 0.2px;
  font-weight: bold;
  text-shadow: 0.2px 0.2px 0.3px; // doesn't work on safari
}
@mixin sans {
  font-family: 'DarkerGrotesque', $systemSans;
  letter-spacing: -0.005em;
}
// @mixin serif {
//   font-family: $customSerif, $systemSerif;
// }
