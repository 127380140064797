// $light: hsl(random(360), 100, 99);
// $lightColor: desaturate(darken($light, 6%), 3%);
// $ink: desaturate(darken($light, 90%), 3%);

$brown: #422e23;
$green: #6cbaa8;
$blue: #90c1db;
$orange: #ff7a21;
$lightBlue: #daeaf3;
$red: #dc2f36;

$ink: $brown;
$white: #ffffff;
$light: #f3f3f3;
$pale: #e8e0c8;

$darkBrown: #2f190c;
$lightBrown: rgb(141, 133, 130);

$blueShadow: rgba(144, 193, 219, 0.2);
$lightGreen: #e5f3f0;
$lightRed: #fbe5e6;
$lightOrange: rgb(253, 230, 215);

$paleShadow: rgba(232, 224, 200, 0.3);

$gray: #3f5a66;
$dark: rgba(43, 29, 28, 1);


  $light2: #f8f8f8;
$lightBrown: rgb(141, 133, 130);

$brownShadow: rgba(66, 46, 35, 0.2);

// shadows
$shadow: rgba(40, 34, 23, 0.1);
$darkWhite: $light;
$darkShadow: RGBA(47, 25, 12, 0.5);
