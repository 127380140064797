$base: '../';

@font-face {
  font-family: 'NationalPark';
  font-style: normal;
  font-weight: normal;
  src: local('NationalPark'),
    url($base+'/fonts/NationalPark/NationalPark-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'NationalPark Bold';
  // font-style: bold;
  // font-weight: 700;
  src: local('NationalPark Bold'),
    url($base+'/fonts/NationalPark/NationalPark-Heavy.otf') format('opentype');
}

@font-face {
  font-family: 'DarkerGrotesque';
  font-style: normal;
  font-weight: normal;
  src: local('DarkerGrotesque'),
    url($base+'/fonts/DarkerGrotesque/DarkerGrotesque-Medium.ttf')
      format('truetype');
}
// @font-face {
//   font-family: 'DarkerGrotesque';
//   font-style: bold;
//   font-weight: 700;
//   src: local('DarkerGrotesque'),
//     url($base+'/fonts/DarkerGrotesque/DarkerGrotesque-Bold.ttf')
//       format('truetype');
// }
@font-face {
  font-family: 'DarkerGrotesque';
  font-style: black;
  font-weight: 900;
  src: local('DarkerGrotesque'),
    url($base+'/fonts/DarkerGrotesque/DarkerGrotesque-Black.ttf')
      format('truetype');
}
